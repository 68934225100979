import { AlertCallsService } from './api/alert-calls.service';
import { ShalncoService } from './api/shalnco.service';
import { LoaderService } from './shared/loader/loader.service';
import { TokenInterceptor } from './../../http.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { SubpagesComponent } from './layouts/subpages/subpages.component';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    SubpagesComponent,
    LoaderComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgxPageScrollModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    LoaderService,
    ShalncoService,
    AlertCallsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
