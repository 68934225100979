import { SubpagesComponent } from './layouts/subpages/subpages.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: BlankComponent,
    children: [
      { path: '', redirectTo: '/landing', pathMatch: 'full' },
      {
        path: 'landing',
        loadChildren: './landing/landing.module#LandingModule'
      }
    ]
  },
  {
    path: 'landing',
    component: SubpagesComponent,
    children: [
      {
        path: 'sub',
        loadChildren: './landing/landing.module#LandingModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/starter'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
