import { Component, OnInit, Inject } from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-subpages',
  templateUrl: './subpages.component.html',
  styleUrls: ['./subpages.component.scss']
})
export class SubpagesComponent implements OnInit {
  today = new Date();
  year;
  menu_id = 1;
  issidemenu = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public route: Router
  ) {
    this.year = this.today.getFullYear();
    if (this.route.url === '/landing/sub/courses') {
      this.menu_id = 2;
    } else if (this.route.url === '/landing/sub/bizinbits') {
      this.menu_id = 3;
    } else if (this.route.url === '/landing/sub/publications') {
      this.menu_id = 4;
    } else if (this.route.url === '/landing/sub/contact') {
      this.menu_id = 5;
    }
  }

  ngOnInit() {}

  showSideMenu() {
    this.issidemenu = true;
    this.document.body.classList.add('mobile-nav-active');
  }
  closeSideMeun() {
    this.issidemenu = false;
    this.document.body.classList.remove('mobile-nav-active');
  }
}
