import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, public _router: Router) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      localStorage.getItem('token') !== null &&
      localStorage.getItem('token') !== ''
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: localStorage.getItem('token'),
        },
      });
      return next.handle(request).pipe(
        catchError((err) => {
          if (err.status === 401) {
            localStorage.clear();
            this._router.navigateByUrl('/#');
          }
          const error = err.error || err.statusText;
          return throwError(error);
        }),
      );
    } else if (
      localStorage.getItem('otpToken') !== null &&
      localStorage.getItem('otpToken') !== ''
    ) {
      request = request.clone({
        setHeaders: {
          OTPToken: localStorage.getItem('otpToken'),
        },
      });
      return next.handle(request).pipe(
        catchError((err) => {
          if (err.status === 401) {
            localStorage.clear();
            this._router.navigateByUrl('/#');
          }
          const error = err.error || err.statusText;
          return throwError(error);
        }),
      );
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
        },
      });
      return next.handle(request).pipe(
        catchError((err) => {
          if (err.status === 401) {
            localStorage.clear();
            this._router.navigateByUrl('/#');
          }
          localStorage.clear();
          const error = err.error || err.statusText;
          return throwError(error);
        }),
      );
    }
  }
}
