import { environment } from './../../environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShalncoService {
  constructor(private http: HttpClient) {}

  addContact(params): Observable<any> {
    return this.http
      .post(environment._url + 'contact/addNewContact', params)
      .pipe(catchError(this.handleError));
  }

  getBooks(): Observable<any> {
    return this.http
      .get(environment._url + 'book/getBooks')
      .pipe(catchError(this.handleError));
  }

  addSignup(params): Observable<any> {
    return this.http
      .post(environment._url + 'contact/addNewSignupUser', params)
      .pipe(catchError(this.handleError));
  }
  getBlogsTypes(): Observable<any> {
    return this.http
      .get(environment._url + 'content/getBlogTypes')
      .pipe(catchError(this.handleError));
  }

  getBlogs(): Observable<any> {
    return this.http
      .get(environment._url + 'blog/getBlogs')
      .pipe(catchError(this.handleError));
  }

  getBlogDetails(id): Observable<any> {
    return this.http
      .get(environment._url + 'blog/getBlogDetails/' + id)
      .pipe(catchError(this.handleError));
  }

  getBlogComments(id): Observable<any> {
    return this.http
      .get(environment._url + 'blog/getBlogComments/' + id)
      .pipe(catchError(this.handleError));
  }

  addBlogComments(params): Observable<any> {
    return this.http
      .post(environment._url + 'blog/addBlogComment', params)
      .pipe(catchError(this.handleError));
  }

  handleError(error: Response | any) {
    return throwError(error.error);
  }
}
