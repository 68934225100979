import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  issidemenu = false;
  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.log(this.router.url);
  }

  ngOnInit() {}

  showSideMenu() {
    this.issidemenu = true;
    this.document.body.classList.add('mobile-nav-active');
  }
  closeSideMeun() {
    this.issidemenu = false;
    this.document.body.classList.remove('mobile-nav-active');
  }
}
