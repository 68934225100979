import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertCallsService {
  constructor() {}

  successSwal(title, meg) {
    Swal.fire(title, meg, 'success');
  }
  successhtmlSwal(title, html) {
    // Swal.fire(title, html, 'success');
    Swal.fire({
      type: 'success',
      title,
      html
    });
  }
}
